import "./css/kidshealth-library.scss";

import {Configuration} from "./Models/Configuration/Configuration";
import {ISearchApplicationService} from "./Interfaces/ISearchApplicationService";
import {SearchResponse} from "./Models/SearchResponse";
import {QueryInput} from "./Models/QueryInput";
import {Article} from "./Models/Article";
import {IKidsHealthLibraryService} from "./Interfaces/IKidsHealthLibraryService";
import {StaticKidsHealthLibraryService} from "./Services/StaticKidsHealthLibraryService";
import {KidsHealthLibraryService} from "./Services/KidsHealthLibraryService";
import {SearchApplicationService} from "./Services/SearchApplicationService";
import {CategoryResponse} from "./Models/CategoryResponse";
import {Footer} from "./Models/Footer";
import {SectionResponse} from "./Models/SectionResponse";
import {CategoryInput} from "./Models/CategoryInput";

export class Application {
    private readonly Configuration: Configuration;
    private readonly SearchApplicationService: ISearchApplicationService;
    private readonly KidsHealthLibraryService: IKidsHealthLibraryService;
    private queryParameter: string = 'query';
    private langParameter: string = 'lang';
    private sectionParameter: string = 'section';

    constructor(config: Configuration) {
        this.Configuration = new Configuration();
        Object.assign(this.Configuration, config);
        this.SearchApplicationService = new SearchApplicationService(this.Configuration);
        this.KidsHealthLibraryService = this.Configuration.DebugMode ? new StaticKidsHealthLibraryService() : new KidsHealthLibraryService(this.Configuration);
    }

    public async RenderSearchContent() {
        let formContainer: HTMLElement = document.getElementById(this.Configuration.Markup.FormContainerId);
        formContainer.innerHTML = await this.SearchApplicationService.GenerateFormMarkup(this.Configuration.Language);
        await this.RegisterFormPageEvents();
        (this.Configuration.Licensee === 'demo29') ? console.warn('Warning: Demo content is being displayed') : '';
    }

    public async HandleSectionSelection(event, lang, element: HTMLElement) {
        let option: string = event.target.value;
        let categoryLoading: HTMLElement = document.getElementById('KidsHealthCategoryLoading');
        let categoryContainer: HTMLElement = document.getElementById('KidsHealthCategoryContainer');
        categoryLoading.classList.remove('kh-hide');
        categoryContainer.classList.add('kh-hide');
        if(option) {
            let query = new QueryInput(lang, option);
            let categories: SectionResponse = await this.KidsHealthLibraryService.GetCategoriesBySection(query);
            element.innerHTML = await this.SearchApplicationService.GenerateCategoryOptionsMarkup(categories);
            categoryLoading.classList.add('kh-hide');
            categoryContainer.classList.remove('kh-hide');
            await this.RenderFooterContent(categories.footer);
        } else {
            categoryLoading.classList.add('kh-hide');
        }
    }

    public async HandleCategorySelection(event, lang: string, section: string) {
        let category: string = event.target.value;
        let categoryPageUrl: string = this.Configuration.CategoryPageUrl;
        window.location.hostname.includes('localhost') ? categoryPageUrl = `${categoryPageUrl}.html` : categoryPageUrl;
        window.location.href = `${categoryPageUrl}?lang=${lang}&section=${section}&query=${category}`;
    }

    public async RenderSearchResults() {
        let params = new URLSearchParams(window.location.search);
        let query: string = params.has(this.queryParameter) ? encodeURI(params.get(this.queryParameter)) : '';
        let lang: string = params.has(this.langParameter) ? encodeURI(params.get(this.langParameter)) : '';
        return await this.RenderQueryResultsInternal(lang, query);
    }

    private async RenderQueryResultsInternal(lang: string, query: string) {
        let queryModel = new QueryInput(lang, query);
        let resultsContainer: HTMLElement = document.getElementById(this.Configuration.Markup.ResultsContainerWrapperId);
        resultsContainer.innerHTML = await this.HandleContentLoading();
        let resultsModel: SearchResponse = await this.KidsHealthLibraryService.GetSearchByKeyword(queryModel);
        resultsContainer.innerHTML = await this.SearchApplicationService.GenerateResultsMarkup(resultsModel, queryModel);

        let links: HTMLCollectionOf<HTMLAnchorElement> = resultsContainer.getElementsByTagName('a');
        this.HandleLinks(links);
        this.RenderFooterContent(resultsModel.footer);
    }

    public async RenderArticleContent() {
        // Reconstruct URL to look up.
        let params = new URLSearchParams(window.location.search);
        let query: string = params.has(this.queryParameter) ? encodeURI(params.get(this.queryParameter)): '';
        let lang: string = query.includes('/en/') ? 'en' : 'es';
        let webService: string = this.Configuration.WebService;
        let licensee: string = this.Configuration.Licensee;
        let url: string = `${this.Configuration.KidsHealthBaseUrl}/${webService}/${licensee}${query}`;
        let contentContainer: HTMLElement = document.getElementById(this.Configuration.Markup.ContentContainerWrapperId);
        contentContainer.innerHTML = await this.HandleContentLoading();
        let contentModel: Article = await this.KidsHealthLibraryService.GetArticleContent(url);
        contentContainer.innerHTML = await this.SearchApplicationService.GenerateContentMarkup(contentModel, lang, this.Configuration.Print);
        let links: HTMLCollectionOf<HTMLAnchorElement> = contentContainer.getElementsByTagName('a');
        let scripts: NodeListOf<HTMLScriptElement> = contentContainer.querySelectorAll('script');
        this.HandleLinks(links);
        this.HandleTabContent();
        this.HandleScripts(scripts, this.Configuration.KidsHealthBaseUrl);
        this.HandleCanonical();
        this.HandleMetaContent(contentModel);
        this.HandleSchemaContent(contentModel);
        this.RenderFooterContent(contentModel.footer);

        if(this.Configuration.Print) {
            let print: HTMLElement = document.getElementById('PrintArticle');
            print.addEventListener('click', (event: Event) => {
                event.preventDefault();
                let printString: string = print.getAttribute('href');
                let path: string = window.location.pathname;
                path.endsWith('/') ? path = path.substring(0, path.length -1) : path;
                let newPath: string = path.substring(0, path.lastIndexOf('/') +1);
                let printUrl = `${newPath}${printString}`;
                let size: string = 'height=600, width=800';
                let printWindow: Window = window.open(printUrl, 'Print Article', size + 'scrollbars=yes');
                printWindow.focus();
            });
        }
    }

    public async RenderPrintContent() {
        let params = new URLSearchParams(window.location.search);
        let query: string = params.has(this.queryParameter) ? encodeURI(params.get(this.queryParameter)): '';
        let url: string = `${this.Configuration.KidsHealthBaseUrl}/${this.Configuration.WebService}/${this.Configuration.Licensee}${query}`;
        let contentContainer: HTMLElement = document.getElementById(this.Configuration.Markup.PrintContentId);
        let contentModel: Article = await this.KidsHealthLibraryService.GetArticleContent(url);
        contentContainer.innerHTML = await this.SearchApplicationService.GeneratePrintContentMarkup(contentModel, this.Configuration.Language);

        let links: HTMLCollectionOf<HTMLAnchorElement> = contentContainer.getElementsByTagName('a');
        for(let i = 0; i < links.length; i++) {
            links[i].removeAttribute('href');
        }

        this.RenderFooterContent(contentModel.footer);
        window.print();
        setTimeout(() => {
            window.close();
        }, 500);
    }

    public async RenderCategoryContent() {
        let param = new URLSearchParams(window.location.search);
        let query: string = param.has(this.queryParameter) ? encodeURI(param.get(this.queryParameter)) : '';
        let lang: string = param.has(this.langParameter) ? encodeURI(param.get(this.langParameter)) : '';
        let section: string = param.has(this.sectionParameter) ? encodeURI(param.get(this.sectionParameter)) : '';
        let queryModel = new CategoryInput(lang, section, query);
        let contentContainer: HTMLElement = document.getElementById(this.Configuration.Markup.CategoryContentWrapperId);
        contentContainer.innerHTML = await this.HandleContentLoading();
        let categoryModel: CategoryResponse = await this.KidsHealthLibraryService.GetArticlesByCategory(queryModel);
        contentContainer.innerHTML = await this.SearchApplicationService.GenerateCategoryContent(categoryModel);

        let links: HTMLCollectionOf<HTMLAnchorElement> = contentContainer.getElementsByTagName('a');
        this.HandleLinks(links);
        this.RenderFooterContent(categoryModel.footer);
    }

    public async HandleContentLoading() {
        return `<div id=${this.Configuration.Markup.LoadingContainerId}">
                    <div class="kh-loading-image"><img src="${this.Configuration.LoadingImage}" alt="Loading" /></div>
                    <div class="kh-loading-message"><p>${this.Configuration.LoadingMessage}</p></div>
                </div>`;
    }

    public async HandleLinks(links: HTMLCollectionOf<HTMLAnchorElement>) {
        for(let i = 0; i < links.length; i++) {
            if(links[i].getAttribute('id') !== 'PrintArticle') {
                // Remove hostname, webservice and licensee name from URL string.
                let articleUrl: string = links[i].pathname;
                let articleArray: string[] = articleUrl.split('/');
                let removedArray = articleArray.splice(0, 3);
                articleUrl = articleArray.join('/');
                let newUrl: string;
                if(window.location.hostname.includes('localhost')) {
                    newUrl = `${this.Configuration.ArticlePageUrl}.html?query=/${articleUrl}`;
                    links[i].setAttribute('href', newUrl);
                } else {
                    let path: string = window.location.pathname;
                    path.endsWith('/') ? path = path.substring(0, path.length -1) : path;
                    let newPath: string = path.substring(0, path.lastIndexOf('/') +1);
                    newUrl = `${newPath}${this.Configuration.ArticlePageUrl}?query=/${articleUrl}`;
                    links[i].setAttribute('href', newUrl);
                }
            }
        }
    }

    public async HandleTabContent() {
        let tabHeaders: HTMLCollectionOf<Element> = document.getElementsByClassName('kh-tab-header');
        let tabContents: HTMLCollectionOf<Element> = document.getElementsByClassName('kh-tab-content');
        for(let i = 0; i < tabHeaders.length; i++) {
            (i === 0) ? tabHeaders[i].classList.add('kh-active') : tabHeaders[i].classList.add('kh-inactive');
        }
        for(let i = 0; i < tabContents.length; i++) {
            (i === 0) ? tabContents[i].classList.add('kh-active') : tabContents[i].classList.add('kh-hide');
        }
        for(let i = 0; i < tabHeaders.length; i++) {
            tabHeaders[i].addEventListener('click', (event) => {
                this.HandleTabs(tabHeaders, tabContents, i);
            });
        }

    }

    public async HandleTabs(tabHeaders: HTMLCollectionOf<Element>, tabContents: HTMLCollectionOf<Element>, tab: number) {
        for(let i = 0; i < tabHeaders.length; i++) {
            if(i === tab) {
                tabHeaders[i].classList.add('kh-active');
                tabHeaders[i].classList.remove('kh-inactive');
                tabContents[i].classList.add('kh-active');
                tabContents[i].classList.remove('kh-hide');
            } else {
                tabHeaders[i].classList.add('kh-inactive');
                tabHeaders[i].classList.remove('kh-active');
                tabContents[i].classList.add('kh-hide');
                tabContents[i].classList.remove('kh-active');
            }
        }
    }

    public async HandleScripts(scripts: NodeListOf<HTMLScriptElement>, baseUrl) {
        let body: HTMLElement = document.body;
        let scriptArr: Array<HTMLScriptElement> = [];
        let path: string;
        let videoDiv: HTMLElement;

        scripts.forEach(function(script, i) {
           if(script.src != '') {
               let tag = document.createElement('script');
               // Remove domain if not kidshealth.org as it gets redirected.
               if(script.src.indexOf(baseUrl) === -1) {
                   // Add base URL (kidshealth.org) to script path.
                   let url = new URL(script.src);
                   path = url.pathname;
                   tag.src = baseUrl + path;
               } else {
                   tag.src = script.src;
               }
               // Add scripts to array in order.
               if(tag.src.indexOf('kh-video-metadata') > -1) {
                   scriptArr.splice(0, 0, tag);
               } else if(tag.src.indexOf('kh-video-controller') > -1) {
                   tag.setAttribute('defer', '');
                   scriptArr.splice(1, 0, tag);
               } else {
                   scriptArr.splice(2, 0, tag);
               }
           }
           if(i < 2) {
               // Remove the first two scripts from page.
               script.parentNode.removeChild(script);
           } else {
               // Replace third script with div that video attaches to.
               videoDiv = document.createElement('div');
               videoDiv.classList.add('kh-video-container');
               script.parentNode.replaceChild(videoDiv, script);
           }
        });

        let i = 0;
        let loadScripts = () => {
            if(scriptArr.length > 0) {
                let script = scriptArr.shift();
                script.onload = function(event) {
                    loadScripts();
                };
                body.appendChild(script);

                if(i === 2) {
                    // Get the filename from third script as this is the same as the video ID.
                    let url = script.src.split('/');
                    let filename = url.pop() || url.pop();
                    filename = filename.substr(0, filename.lastIndexOf('.'));
                    videoDiv.id = filename;
                }
                i++;
            }
            else return;
        }
        loadScripts();
    }

    private async HandleMetaContent(meta: Article) {
        let licenseeName: string = this.Configuration.LicenseeFullName ? ` | ${this.Configuration.LicenseeFullName}` : '';
        document.title = `${meta.article_title}${licenseeName}`;
        // Add meta description.
        if(document.querySelector('meta[name=description]')) {
            document.querySelector('meta[name=description]').setAttribute('content', `${meta.meta_description}`);
        } else {
            let metaDesc: HTMLMetaElement = document.createElement('meta');
            metaDesc.setAttribute('name', 'description');
            metaDesc.content = `${meta.meta_description}`;
            document.getElementsByTagName('head')[0].appendChild(metaDesc);

        }
        // Add Open Graph content.
        let openGraphObject: Object = {
            // Required.
            'og:title': `${meta.article_title}`,
            'og:type': 'article',
            'og:url': document.location.href,
            'og:image': `${this.Configuration.DefaultImage}`,
            // Additional.
            'og:site_name': `${this.Configuration.LicenseeFullName}`,
            'og:description': `${meta.meta_description}`,
            'og:locale': `${meta.language}_US`
        };

        Object.keys(openGraphObject).forEach(function(item) {
            let metaProperty: HTMLMetaElement = document.createElement('meta');
            metaProperty.setAttribute('name', item);
            metaProperty.content = openGraphObject[item];
            document.getElementsByTagName('head')[0].appendChild(metaProperty);
        });
    }

    public async HandleCanonical() {
        let url: string = window.location.href;
        let canonical: HTMLLinkElement = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
            canonical.href = url;
        }
    }

    public async HandleSchemaContent(schema: Article) {
        // Create object.
        let schemaBaseObject: Object = {
            '@context': 'http://schema.org',
            '@type': 'MedicalWebPage',
            'name': `${schema.article_title}`,
            'description': `${schema.meta_description}`,
            'keywords': `${schema.meta_keywords}`,
            'inLanguage': `${schema.language}_US`,
            'isFamilyFriendly': true,
            'audience': 'http://schema.org/Patient',
            'author': {
                '@type': 'Organization',
                'name:': `${this.Configuration.KidsHealthBrandName}`
            },
            'copyrightHolder': {
                '@type': 'Organization',
                'name': `${this.Configuration.KidsHealthBrandName}`,
            },
            'dateCreated': `${schema.creation_date}`,
            'dateModified': `${schema.last_modified_date}`,
            'datePublished': `${schema.published_date}`,
            'reviewedBy': {
                '@type': 'Person',
                'name': `${schema.last_medical_reviewer}`
            },
            'lastReviewed': `${schema.last_medical_review_date}`,
            'url': window.location.href
        };
        // Set values.
        let schemaObject: Object = {};
        Object.keys(schemaBaseObject).forEach(function(item) {
            (schemaBaseObject[item]) ? schemaObject[item] = schemaBaseObject[item] : '';
        });
        let script: HTMLScriptElement = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaObject);
        document.getElementById(`${this.Configuration.Markup.ContentContainerId}`).appendChild(script);
    }

    private async RenderFooterContent(footer: Footer) {
        let footerContainer: HTMLElement = document.getElementById(this.Configuration.Markup.FooterContainerWrapperId);
        footerContainer.innerHTML = await this.SearchApplicationService.GenerateFooterMarkup(footer);
    }

    private async RegisterFormPageEvents() {
        let formContainer: HTMLElement = document.getElementById(this.Configuration.Markup.FormContainerId);
        let formButton: HTMLElement = document.getElementById(this.Configuration.Markup.SearchButtonId);
        let queryInput: HTMLElement = document.getElementById(this.Configuration.Markup.SearchInputId);
        let sectionSelect: HTMLElement = document.getElementById(this.Configuration.Markup.SelectInputId);
        let categorySelect: HTMLElement = document.getElementById(this.Configuration.Markup.CategoryInputId);
        let searchError: HTMLElement = document.getElementById('SearchError');
        let section: string = '';

        sectionSelect.addEventListener('change', (event) => {
            let lang: string = document.getElementById(this.Configuration.Markup.SearchInputId).getAttribute('lang');
            section = (<HTMLSelectElement>document.getElementById(this.Configuration.Markup.SelectInputId)).value;
            this.HandleSectionSelection(event, lang, <HTMLElement>categorySelect);
        });
        categorySelect.addEventListener('change', (event) => {
            let lang: string = document.getElementById(this.Configuration.Markup.SearchInputId).getAttribute('lang');
            this.HandleCategorySelection(event, lang, section);
        });

        let languageSelect: HTMLElement = document.getElementById("LanguageSelect");
        languageSelect.addEventListener('click', (event) => {
            event.preventDefault();
            let element = event.currentTarget as HTMLAnchorElement;
            let lang: string = element.lang;
            formContainer.innerHTML = this.SearchApplicationService.GenerateFormMarkup(lang);
            this.RegisterFormPageEvents();
        });

        formButton.addEventListener('click', (event) => {
            event.preventDefault();
            let term: string = `${(<HTMLInputElement>queryInput).value}`;
            let lang: string = document.getElementById(this.Configuration.Markup.SearchInputId).getAttribute('lang');
            let resultsPageUrl: string = this.Configuration.ResultsPageUrl;
            window.location.hostname.includes('localhost') ? resultsPageUrl = `${resultsPageUrl}.html` : resultsPageUrl;
            term ? window.location.href = `${resultsPageUrl}?lang=${lang}&query=${term}` : searchError.classList.remove('kh-hide');
        });

        queryInput.addEventListener('focus', (event) => {
            searchError.classList.add('kh-hide');
        });
    }
}
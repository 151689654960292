export enum Spanish {
    Name = 'Spanish',
    ShortName = 'es',
    AltLang = 'English',
    AltShortName = 'en',
    Parents = 'Padres',
    Teens = 'Adolescentes',
    Kids = 'Niños',
    Search = 'Buscar',
    SearchLabel = 'Buscar en la biblioteca de KidsHealth',
    Print = 'Imprimir'
}
import {IKidsHealthConfiguration} from "../../Interfaces/Configuration/IKidsHealthConfiguration";
import {IMarkupConfiguration} from "../../Interfaces/Configuration/IMarkupConfiguration";
import {MarkupConfiguration} from "./MarkupConfiguration";

export class Configuration implements IKidsHealthConfiguration {
    Markup: IMarkupConfiguration = new MarkupConfiguration();
    BaseUrl: string = 'index.html';
    Language: string = 'en';
    Licensee: string = 'demo29';
    LicenseeFullName: string = '';
    LicenseeLogo: string = '';
    DefaultImage: string = '';
    KidsHealthBrandName: string = 'KidsHealth';
    KidsHealthBaseUrl: string = 'https://kidshealth.org';
    WebService: string = 'ws';
    ArticlePageUrl: string = 'article';
    CategoryPageUrl: string = 'category';
    ResultsPageUrl: string = 'results';
    PrintPageUrl: string = 'print';
    LoadingImage: string = '/node_modules/kidshealth-library/dist/images/loading.gif';
    LoadingMessage: string = 'Loading...';
    DebugMode: boolean = false;
    Print: boolean = true;
}
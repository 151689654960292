import {ISearchApplicationService} from "../Interfaces/ISearchApplicationService";
import {Article} from "../Models/Article";
import {SearchResponse} from "../Models/SearchResponse";
import {Footer} from "../Models/Footer";
import {CategoryResponse} from "../Models/CategoryResponse";
import {EdArticle} from "../Models/EdArticle";
import {SectionResponse} from "../Models/SectionResponse";
import {English} from "../Enums/English";
import {Spanish} from "../Enums/Spanish";
import {IKidsHealthConfiguration} from "../Interfaces/Configuration/IKidsHealthConfiguration";

export class SearchApplicationService implements ISearchApplicationService {
    private Configuration: IKidsHealthConfiguration;

    constructor(config: IKidsHealthConfiguration) {
        this.Configuration = config;
    }

    public GenerateCategoryContent(model: CategoryResponse): string {
        let LinkedArticles = model.linked_articles.filter(article => !article.article_full_url.includes('/center/'))
            .map((article) => {
            return `<li class="kh-article" data-category-object-id="${article.article_object_id}">
                    <h4 class="kh-title"><a href="${article.article_full_url}">${article.article_title}</a></h4>
                    <p class="kh-summary">${article.article_summary}</p>
                    </li>`}).join('');

        let SubCategories = model.sub_categories.map((category) => {
            return `<div class="kh-subcategory" data-category-object-id="${category.category_object_id}">
                    <h2 class="kh-title">${category.category_full_title}</h2>
                    <p class="kh-description">${category.category_description}</p>
                    <ul class="kh-article-list">${category.linked_articles.map((article) => {
                        return `<li class="kh-article" data-article-object-id="${article.article_object_id}">
                                <h4 class="kh-title"><a href="${article.article_full_url}">${article.article_title}</a></h4>
                                <p class="kh-summary">${article.article_summary}</p>
                                </li>`}).join('')}
                    </div>`}).join('') + '</ul>';

        return `<div id="${this.Configuration.Markup.CategoryContentId}">
                <h1>${model.category_full_title}</h1>
                <p class="kh-description">${model.category_description}</p>
                <ul class="kh-article-list">${LinkedArticles}</ul>
                ${SubCategories}
                </div>`
    }

    public GenerateContentMarkup(model: Article, language: string, print: boolean): string {
        let lang = (language === 'en') ? English : Spanish;

        let parents = [];
        let teens = [];
        let kids = [];
        let parentsTab: string = '';
        let parentsContent: string = '';
        let teensTab: string = '';
        let teensContent: string = '';
        let kidsTab: string = '';
        let kidsContent: string = '';
        let relatedArticles: string = '';
        let printArticle: string = '';

        let pairedArticle: string = '';

        if(model.paired_article?.article_title) {
            pairedArticle = `<div class="kh-paired-article">
                                 <a href="${model.paired_article.article_full_url}">
                                     <span class="kh-language" lang="${lang.ShortName}">${lang.AltLang}: </span>
                                     <span class="kh-title" lang="${lang.ShortName}">${model.paired_article.article_title}</span>
                                 </a>
                             </div>`;
        }

        model.related_articles?.map((related) => {
            if (related.article_full_url.includes('/parents/')) {
                parents.push(related);
            }
            if (related.article_full_url.includes('/teens/')) {
                teens.push(related);
            }
            if (related.article_full_url.includes('/kids/')) {
                kids.push(related);
            }
        });

        let parentsList: string = parents.map((parent) => {
            return SearchApplicationService.GenerateRelatedArticles(parent)}).join('');

        let teensList: string = teens.map((teen) => {
            return SearchApplicationService.GenerateRelatedArticles(teen)}).join('');

        let kidsList: string = kids.map((kid) => {
            return SearchApplicationService.GenerateRelatedArticles(kid)}).join('');

        if(parentsList.length) {
            parentsTab = `<div class="kh-tab-header kh-parents">${lang.Parents}</div>`;
            parentsContent = `<div class="kh-tab-content kh-parents"><ul>${parentsList}</ul></div>`;
        }

        if(teensList.length) {
            teensTab = `<div class="kh-tab-header kh-teens">${lang.Teens}</div>`;
            teensContent = `<div class="kh-tab-content kh-teens"><ul>${teensList}</ul></div>`;
        }

        if(kidsList.length) {
            kidsTab = `<div class="kh-tab-header kh-kids">${lang.Kids}</div>`;
            kidsContent = `<div class="kh-tab-content kh-kids"><ul>${kidsList}</ul></div>`;
        }

        if(parentsList.length || teensList.length || kidsList.length) {
            relatedArticles = `<div id="RelatedArticles">
                                ${parentsTab}
                                ${teensTab}
                                ${kidsTab}
                                ${parentsContent}
                                ${teensContent}
                                ${kidsContent}
                               </div>`;
        }

        let reviewedByContent = (model.last_medical_reviewer || model.last_medical_review_date) ? SearchApplicationService.GenerateReviewedByContent(model.last_medical_reviewer, model.last_medical_review_date) : '';

        if(print) {
            let query: string = window.location.search;
            let suffix: string = window.location.hostname.includes('localhost') ? '.html': '';
            let href: string = `${this.Configuration.PrintPageUrl}${suffix}${query}`;
            printArticle = `<div id="PrintArticleWrapper"><p><a href="${href}" title="This link will open in a new window." rel="nofollow" id="PrintArticle" class="kh-print">${lang.Print}</a></p></div>`;
        }

        return `
            <div id="${this.Configuration.Markup.ContentContainerId}">
                <h1 class="kh-title">${model.article_title}</h1>
                ${pairedArticle}
                <div id="KidsHealthArticle">
                    <div class="kh-article-body">
                        ${model.article_body_page_html}
                    </div>
                    ${reviewedByContent}
                </div>
                ${relatedArticles}
                ${printArticle}
            </div>
        `;
    }

    public GenerateFormMarkup(language: string): string {
        let lang = (language === 'en') ? English : Spanish;

        return `<div id="${this.Configuration.Markup.FormContainerId}">
                    <h2>${this.Configuration.Markup.FormTitleText}</h2>
                    <fieldset>
                        <div class="kh-language"><a href="" lang="${lang.AltShortName}" aria-label="${lang.AltLang}" id="LanguageSelect">${lang.AltLang}</a></div>
                        <div id="${this.Configuration.Markup.SearchContainerId}">
                            <label for="${this.Configuration.Markup.SearchInputId}" class="kh-label Hide">${lang.SearchLabel}</label>
                            <input type="text" id="${this.Configuration.Markup.SearchInputId}" name="kidshealth-keyword" maxlength="256" placeholder="" lang="${lang.ShortName}" required="required" />
                            <button id="${this.Configuration.Markup.SearchButtonId}" type="submit" name="kidshealth-submit">${lang.Search}</button>
                            <div id="SearchError" class="kh-error kh-hide">
                                <span>Please enter a search term</span>
                            </div>
                        </div>
                        <div id="${this.Configuration.Markup.SelectContainerId}">
                            <div id="${this.Configuration.Markup.SelectInputContainerId}">
                                <label for="${this.Configuration.Markup.SelectInputId}">${this.Configuration.Markup.FormSelectSectionLabelText}:</label>
                                <select id="${this.Configuration.Markup.SelectInputId}">
                                    <option value="">Select</option>
                                    <option value="parents">${lang.Parents}</option>
                                    <option value="teens">${lang.Teens}</option>
                                    <option value="kids">${lang.Kids}</option>
                                </select>
                            </div>
                            <div id="${this.Configuration.Markup.CategoryInputContainerId}">
                                <div id="KidsHealthCategoryLoading" class="kh-loading-image kh-hide"><img src="${this.Configuration.LoadingImage}" alt="Loading" /></div>
                                <div id="KidsHealthCategoryContainer" class="kh-hide">
                                    <label for="${this.Configuration.Markup.CategoryInputId}">${this.Configuration.Markup.FormSelectCategoryLabelText}:</label>
                                    <select id="${this.Configuration.Markup.CategoryInputId}"></select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>`;
    }

    public GenerateCategoryOptionsMarkup(model: SectionResponse): string {
        let optionsList = model.top_level_categories.sort((a, b) => (a.category_full_title > b.category_full_title) ? 1 : -1);

        let options = optionsList.map((option) => {
            return `<option value="${option.category_name}">${option.category_full_title}</option>`;
        }).join('');

        return `<option value="">Category</option>
                ${options}`;
    }

    public GenerateResultsMarkup(model: SearchResponse): string {
        if(model.kharticle === undefined || model.kharticle?.length <= 0)
            return '<h3>No search results found</h3><p>Please try searching using alternative keywords.</p>';

        let results = model.kharticle.filter(result => !result.article_full_url.includes('/center/'))
            .map((result) => {
                return `<li class="kh-article-item">
                    <p class="kh-section"><span>${result.section_name}</span></p>
                    <h4 class="kh-title"><a href="${result.article_full_url}">${result.article_title}</a></h4>
                    <p class="kh-description">${result.meta_description}</p>
                </li>`}).join('');

        return `<ul id="${this.Configuration.Markup.ResultsContainerId}">${results}</ul>`;
    }

    public GeneratePrintContentMarkup(model: Article, language: string): string {
        let setLang = document.getElementsByTagName('html')[0];
        setLang.setAttribute('lang', language);

        document.title = `${model.article_title}` +  ` (for ` +  `${model.section_name}` + `) - Print Version ${this.Configuration.LicenseeFullName}`;

        let licenseeLogo = this.Configuration.LicenseeLogo ? `<img src="${this.Configuration.LicenseeLogo}" alt="${this.Configuration.LicenseeFullName}">` : '';
        let reviewedByContent = (model.last_medical_reviewer || model.last_medical_review_date) ? SearchApplicationService.GenerateReviewedByContent(model.last_medical_reviewer, model.last_medical_review_date) : '';

        return `
            <div id="${this.Configuration.Markup.PrintContentId}">
                <div id="KidsHealthPrintHeader" class="kh-clear">
                    <div class="kh-print-header-left">
                        ${licenseeLogo}
                    </div>
                    <div class="kh-print-header-right">
                        <p>${this.Configuration.LicenseeFullName}</p>
                    </div>
                </div>
                <h1 class="kh-title">${model.article_title}</h1>
                <div id="KidsHealthArticle">
                    <div class="kh-article-body">
                        ${model.article_body_page_html}
                    </div>
                    ${reviewedByContent}
                </div>
            </div>
        `;

    }

    public GenerateFooterMarkup(model: Footer): string {
        let disclaimer = (model.disclaimer) ? `<p class="kh-disclaimer">${model.disclaimer}</p>` : '';
        let copyright = (model.copyright) ? `<p class="kh-copyright">${model.copyright}</p>` : '';
        let logo = (model.logo) ? `<img src="${model.logo}" alt="" class="kh-logo" />` : '';

        return `<div id="${this.Configuration.Markup.FooterContentId}" class="kh-clear">
                    <div class="kh-footer-left">
                        ${disclaimer}
                        ${copyright}
                    </div>
                    <div class="kh-footer-right">
                        ${logo}
                    </div>
                </div>`;
    }

    private static GenerateRelatedArticles(article: EdArticle): string {
        return `<li>
                <h4 class="kh-title"><a href="${article.article_full_url}">${article.article_title}</a></h4>
                </li>`
    }

    private static GenerateReviewedByContent(name: string, date: string): string {
        let reviewedBy = (name) ? SearchApplicationService.GenerateReviewedBy(name) : '';
        let reviewedDate = (date) ? SearchApplicationService.GenerateReviewedDate(date) : '';
        if(reviewedBy || reviewedDate) {
            return `<div class="kh-reviewed">
                        ${reviewedBy}
                        ${reviewedDate}
                    </div>`
        }
    }

    private static GenerateReviewedDate(date: string): string {
        let lastMedicalReviewDate = date;
        let dateParts: string[] = lastMedicalReviewDate.split('/');
        const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        lastMedicalReviewDate = months[parseInt(dateParts[0], 10) -1] + ' ' + dateParts[2];
        return `<p class="kh-date-text"><span class="kh-date-reviewed">Date reviewed:</span> <span class="kh-reviewed-date">${lastMedicalReviewDate}</span></p>`;
    }

    private static GenerateReviewedBy(name: string): string {
        return `<p class="kh-date-text"><span class="kh-date-reviewed">Reviewed by:</span> <span class="kh-reviewed-date">${name}</span></p>`;
    }
}
export enum English {
    Name = 'English',
    ShortName = 'en',
    AltLang = 'En Español',
    AltShortName = 'es',
    Parents = 'Parents',
    Teens = 'Teens',
    Kids = 'Kids',
    Search = 'Search',
    SearchLabel = 'Search KidsHealth library',
    Print = 'Print'
}
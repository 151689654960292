import {IMarkupConfiguration} from "../../Interfaces/Configuration/IMarkupConfiguration";

export class MarkupConfiguration implements IMarkupConfiguration {
    CategoryContentWrapperId: string = 'KidsHealthCategoryContentWrapper';
    CategoryContentId: string = 'KidsHealthCategoryContent';
    CategoryInputContainerId: string = 'KidsHealthCategorySelectContainer';
    CategoryInputId: string = 'KidsHealthCategorySelect';
    ContentContainerId: string = 'KidsHealthContent';
    ContentContainerWrapperId: string = 'KidsHealthContentWrapper';
    FooterContainerWrapperId: string = 'KidsHealthFooterContentWrapper';
    FooterContentId: string = 'KidsHealthFooter';
    FormContainerId:string = 'KidsHealthSearchForm';
    FormWrapperId: string = 'KidsHealthSearchWrapper';
    FormTitleText: string = 'Search KidsHealth Library';
    FormSelectSectionLabelText: string = 'Select topics for';
    FormSelectCategoryLabelText: string = 'Select articles about';
    LoadingContainerId: string = 'KidsHealthLoading';
    PrintContainerWrapperId: string = 'KidsHealthPrintWrapper';
    PrintContentId: string = 'KidsHealthPrintContentWrapper';
    ResultsContainerId: string = 'KidsHealthSearchResults';
    ResultsContainerWrapperId: string = 'KidsHealthSearchResultsWrapper';
    SearchContainerId: string = 'KidsHealthSearchContainer';
    SearchLabelText: string = 'Search KidsHealth Library';
    SearchButtonText: string = 'Search';
    SearchButtonId: string = 'KidsHealthSearchButton';
    SearchInputId: string = 'KidsHealthQueryInput';
    SelectContainerId: string = 'KidsHealthSelectContainer';
    SelectInputContainerId: string = 'KidsHealthSectionSelectContainer';
    SelectInputId: string = 'KidsHealthSectionSelect';
}
import axios from 'axios';
import {IKidsHealthLibraryService} from "../Interfaces/IKidsHealthLibraryService";
import {IKidsHealthConfiguration} from "../Interfaces/Configuration/IKidsHealthConfiguration";
import {Article} from "../Models/Article";
import {SearchResponse} from "../Models/SearchResponse";
import {QueryInput} from "../Models/QueryInput";
import {CategoryResponse} from "../Models/CategoryResponse";
import {CategoryInput} from "../Models/CategoryInput";
import {SectionResponse} from "../Models/SectionResponse";

export class KidsHealthLibraryService implements IKidsHealthLibraryService {
    private Configuration: IKidsHealthConfiguration;

    constructor(config: IKidsHealthConfiguration) {
        this.Configuration = config;
    }

    async GetSearchByKeyword(queryModel: QueryInput): Promise<SearchResponse> {
        let searchResponse: SearchResponse = undefined;
        let baseUrl: string = this.Configuration.KidsHealthBaseUrl;
        let licensee: string = this.Configuration.Licensee;
        let webService: string = this.Configuration.WebService;
        await axios.get<SearchResponse>(`${baseUrl}/${webService}/${licensee}/searchbykeyword/?lang=${queryModel.Lang}&key=${queryModel.Query}`)
            .then(response => {
                searchResponse = response.data;
            })
            .catch(error => {
                console.error('01: GetSearchByKeyword failed to get search result content from KidsHealth API', error);
            });
        return searchResponse;
    }

    async GetCategoriesBySection(queryModel: QueryInput): Promise<SectionResponse> {
        let sectionResponse: SectionResponse = undefined;
        let baseUrl: string = this.Configuration.KidsHealthBaseUrl;
        let licensee: string = this.Configuration.Licensee;
        let webService: string = this.Configuration.WebService;
        await axios.get<SectionResponse>(`${baseUrl}/${webService}/${licensee}/sitenav/${queryModel.Lang}/${queryModel.Query}/`)
            .then(response => {
                sectionResponse = response.data;
            })
            .catch(error => {
                console.error('02: GetCategoriesBySection failed to get category list from KidsHealth API', error);
            });
        return sectionResponse;
    }

    async GetArticlesByCategory(queryModel: CategoryInput): Promise<CategoryResponse> {
        let categoryResponse: CategoryResponse = undefined;
        let baseUrl: string = this.Configuration.KidsHealthBaseUrl;
        let licensee: string = this.Configuration.Licensee;
        let webService: string = this.Configuration.WebService;
        await axios.get<CategoryResponse>( `${baseUrl}/${webService}/${licensee}/${queryModel.Lang}/${queryModel.Section}/${queryModel.Query}`)
            .then(response => {
                categoryResponse = response.data;
        })
        .catch(error => {
            console.error('03: GetArticlesByCategory failed to get category article list from KidsHealth API', error);
        });
        return categoryResponse;
    }

    async GetArticleContent(contentUrl: string): Promise<Article> {
        let articleResponse: Article = undefined;
        await axios.get<Article>(contentUrl)
            .then(response => {
                articleResponse = response.data;
            })
            .catch(error => {
                console.error('04: GetArticleContent failed to get article content from KidsHealth API', error)
            });
        return articleResponse;
    }
}
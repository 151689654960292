export class CategoryInput {
    Lang: string;
    Section: string;
    Query: string;

    constructor(lang: string, section: string, query: string) {
        this.Lang = lang;
        this.Section = section;
        this.Query = query;
    }
}